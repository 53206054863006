import React from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import Matchlist from "@/feature-reskin-probuilds-net/components/Matchlist.jsx";
import playerImage from "@/feature-reskin-probuilds-net/utils/player-image.mjs";
import playerLink from "@/feature-reskin-probuilds-net/utils/player-link.mjs";
import Static from "@/game-lol/utils/static.mjs";
import Container from "@/shared/ContentContainer.jsx";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function Team() {
  const { t } = useTranslation();
  const {
    parameters: [teamId],
  } = useRoute();
  const {
    lol: { proBuildPros = [], proBuildTeams = [], proBuildTeamMatchlist = {} },
  } = useSnapshot(readState);

  const teamInfo = proBuildTeams.find((team) => team.id === teamId);
  const teamMatchlist = proBuildTeamMatchlist[teamId];
  const roster = (teamInfo?.players || []).map((teamPlayer) => {
    return proBuildPros.find((proPlayer) => teamPlayer.id === proPlayer.id);
  });

  return (
    <Container>
      <img
        src={Static.getProTeamImage(teamInfo?.pictureUrl)}
        width="80"
        height="80"
        alt={teamInfo?.name}
      />
      <h1>{teamInfo?.name}</h1>
      <Matchlist currRole={null} matches={teamMatchlist} />
      {roster ? (
        <>
          <h3>{t("", "Team Roster")}</h3>
          <ul>
            {teamInfo.players.map((player) => {
              return (
                <li key={player.name}>
                  <a href={playerLink(player.id)}>
                    <img
                      src={playerImage(player.portraitImageUrl)}
                      width="62"
                      height="62"
                      alt={player.name}
                    />
                    <span className="type-caption--bold">{player.name}</span>
                  </a>
                </li>
              );
            })}
          </ul>
        </>
      ) : null}
    </Container>
  );
}

export default Team;
